"use client";

// Error components must be Client Components
import { useEffect } from "react";
export const metadata = {
  title: "Erreur - Dynabuy Clubs d'affaires",
  description: "Bienvenue sur la page d'erreur des clubs d'affaires de Dynabuy"
};
export default function Error({
  error,
  reset
}) {
  useEffect(() => {
    // TODO FHA : send error on a send by email api route?
    // console.error(error)
  }, [error]);
  return <main className="flex min-h-screen flex-col items-center justify-between p-24" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <div>
        <h1 className={`mb-3 text-2xl font-semibold`}>
          Quelque chose s'est mal passé !
        </h1>
        <button onClick={() => reset()}>Revenir à l'accueil</button>
      </div>
    </main>;
}